import React from "react";
import GContainer from "../components/GContainer";
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";

//last
import Box from "@material-ui/core/Box";

function GTitle(props) {
  return (
    <Box pb={2}>
      <Typography variant="h6">{props.children}</Typography>
    </Box>
  );
}

function GBody(props) {
  return (
    <Box pb={8}>
      <Typography variant="body1">{props.children}</Typography>
    </Box>
  );
}

function GSubBody(props) {
  return <Box pt={5}>{props.children}</Box>;
}

export default function App() {
  return (
    <>
      <Helmet>
        <title>XVision - Privacy</title>
      </Helmet>

      <Box pt="4rem" pb="6rem" bgcolor="#F7F7F7">
        <GContainer maxW="md">
          <Box pb={2}>
            <Typography variant="h1" align="center" paragraph>
              Privacy Policy
            </Typography>
          </Box>
          <Typography variant="h5" align="center" paragraph>
            Your data is important and private. That&apos;s why we take your privacy seriously.
          </Typography>
        </GContainer>
      </Box>

      <Box pt="4rem">
        <GContainer>
          <GTitle>Overview</GTitle>
          <GBody>
            This document outlines the terms of use and the privacy policy about the data you
            generate and how it is handled by our apps. Your data is important and private, which is
            why it is an important issue to disclose and explain. Your privacy is taken very
            seriously.
          </GBody>

          <GTitle>Information Collected</GTitle>
          <GBody>
            No identifiable or non-identifiable information is ever collected and transmitted to any
            XVision servers. No analytics are taken from using our apps. We do not collect any
            sensitive information. Nothing!
          </GBody>

          <GTitle>How We Use Your Information</GTitle>
          <GBody>
            As no information about you is collected, we can not therefore use any information!
          </GBody>

          <GTitle>Children Privacy</GTitle>
          <GBody>
            No data is collected about any children&apos;s usage of our apps in any way.
          </GBody>

          <GTitle>Third Parties</GTitle>
          <GBody>
            As we do not collect your information, we therefore do not and can not sell information
            to any third parties or advertisers.
          </GBody>

          <GTitle>Contact Information</GTitle>
          <GBody>
            If you have any concerns or questions about this policy or anything at all, please send
            an email to: support@xvision.me
          </GBody>

          <GTitle>Terms Of Use</GTitle>
          <GBody>
            By accessing our website or apps, you agree to the following terms of service, and any
            other applicable laws or regulations, including copyright and trademark law.
            <GSubBody>
              DISCLAIMER - Your use of the service is at your sole risk. The Service is provided on
              an &quot;AS IS&quot; basis. The Service is provided without warranties of any kind,
              whether express or implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose, non-infringement or course of
              performance.
            </GSubBody>
            <GSubBody>
              LIMITATIONS - In no event shall we, or our suppliers, be liable for any damages
              (including, without limitation, damages for loss of data or profit, or due to business
              interruption) arising out of the use or inability to use the Service.
            </GSubBody>
            <GSubBody>
              MODIFICATIONS - We may revise these terms of service at any time without notice. By
              using our software you are agreeing to be bound by the then current version of these
              terms of service.
            </GSubBody>
            <GSubBody>
              GOVERNING LAW - These terms and conditions are governed by and construed in accordance
              with the laws of Singapore and you irrevocably submit to the exclusive jurisdiction of
              the courts in that location.
            </GSubBody>
            <GSubBody>Last updated on March 9, 2019.</GSubBody>
          </GBody>
        </GContainer>
      </Box>
    </>
  );
}
